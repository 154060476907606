import { environment as environmentBase } from './environment.base';

export const environment = {
  ...environmentBase,
  metadata: {
    name: 'cp',
    description: 'cyber portal',
    environment: 'production'
  },
  baseUrl: 'https://wuerttembergische.co-IT.eu',
  apiUrl: 'https://api-wuerttembergische.co-IT.eu',
  secrets: {
    RECAPTCHAV2KEY: '6LfDmF0aAAAAAOoQAHYlSwW8vVD9szEvSBw2XQic'
  },
  features: {
    MAINTENANCE: false
  },
  subscriptions: {
    defaultAwarenessPageRedirectUrl: 'https://wuerttembergische.co-it.eu/cyber-portal/phishing-mail-clicked'
  }
};
